import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CallToAction = ({ title, description, btnName, featuredImage }) => {
    return (
        <div className="container py-5">
            <div className="text-center">
                {featuredImage && (
                    <GatsbyImage
                        className="d-inline-block mx-auto rounded-circle mb-2"
                        image={getImage(featuredImage?.node?.localFile)}
                        alt={featuredImage.altText || "Logo"}
                    />
                )}
            </div>
            <h3 className="section-title font-weight-bold text-center mb-2">
                {title}
            </h3>
            <div
                className="section-intro mx-auto text-center mb-3"
                dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            <div className="text-center">
                <Link className="theme-btn-on-bg btn" to="/contacts">
                    {btnName}
                </Link>
            </div>
        </div>
    );
};

export default CallToAction;
