import React from "react";

const Features = ({ features }) => {
    const featuresPerRow = 3; // 3 columns per row
    const totalRows = Math.ceil(features.length / featuresPerRow);

    return (
        <div className="container">
            <div className="row">
                {features.map((feature, index) => {
                    // Determine the current row number
                    const currentRow = Math.ceil((index + 1) / featuresPerRow);

                    // Check if it's the last row
                    const isLastRow = currentRow === totalRows;

                    return (
                        <div
                            key={index}
                            className={`col-12 col-lg-4 mb-5 mb-3 mb-lg-0 ${isLastRow && totalRows !== 1 ? "py-lg-5" : ""}`}
                        >
                            <div className="bg-white shadow-sm py-4 px-4 position-relative">
                                <h3 className="text-center fs-5 fw-bold mb-3 mt-5">
                                    {feature.title}
                                </h3>
                                <div className="icon-holder position-absolute d-inline-block rounded-circle text-center">
                                    <i
                                        className={`${feature.icon} fs-2 text-white`}
                                    ></i>
                                </div>
                                <ul className="list-unstyled text-secondary">
                                    {feature.items.map((item, itemIndex) => (
                                        <li key={itemIndex} className="mb-2">
                                            <i className="bi bi-check mr-2 text-primary"></i>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Features;