import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Hero = ({ title, description, btnName, btnUrl, featuredImage }) => {
    return (
        <div className="header-intro theme-bg-primary shadow-sm py-5">
            <div className="container">
                <div className="profile-teaser media flex-column flex-md-row">
                    {featuredImage && (
                        <GatsbyImage
                            className="profile-image mb-3 mb-md-0 mr-md-5 ml-md-0 rounded mx-auto"
                            image={getImage(featuredImage?.node?.localFile)}
                            alt={featuredImage.altText || "Logo"}
                            fetchpriority="high"
                        />
                    )}

                    <div className="media-body text-center text-md-left">
                        <h1 className="mt-0 font-weight-bold">
                            {title}
                        </h1>
                        <div
                            className="bio mb-3"
                            dangerouslySetInnerHTML={{ __html: description }}
                        ></div>
                        <Link className="btn btn-primary" to={btnUrl}>
                            {btnName}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
