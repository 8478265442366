import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as styles from "../PostArchives.module.css";
import { useTranslation } from "react-i18next";
import "../../i18n";

const Posts = ({ posts }) => {
    const { t } = useTranslation();

    return (
        <div className="container py-5 mt-lg-5 mb-5">
            <h3 className="section-title section-title-border border-primary font-weight-bold text-center">
                {t("news")}
            </h3>
            <div className="blog-cards row equal-height-row">
                {posts.map((post) => (
                    <div key={post.id} className="col-12 col-lg-6">
                        <div className="card rounded-0 border-0 shadow-sm mb-5">
                            <div className="card-img-container position-relative">
                                <figure className={styles.figure}>
                                    <GatsbyImage
                                        className="img-fluid"
                                        image={getImage(
                                            post.featuredImage?.node?.localFile
                                        )}
                                        alt={post.featuredImage?.node?.altText}
                                    />
                                </figure>
                                <div className="card-img-overlay overlay-mask overlay-logo text-center p-0">
                                    <div className="overlay-mask-content text-center w-100 position-absolute">
                                        <Link
                                            className="btn btn-primary"
                                            to={`/blog/${post.slug}`}
                                        >
                                            {t("readMore")}
                                        </Link>
                                    </div>
                                    <Link
                                        className="overlay-mask-link position-absolute w-100 "
                                        to={`/blog/${post.slug}`}
                                    ></Link>
                                </div>
                            </div>
                            <div className="card-body pb-4">
                                <h2 className="card-title text-truncate fw-bold mb-2">
                                    <Link to={`/blog/${post.slug}`}>
                                        {post.title}
                                    </Link>
                                </h2>

                                <ul className="meta list-inline mb-1">
                                    <li className="list-inline-item mr-3">
                                        <i className="bi bi-clock mr-2"></i>
                                        {post.date}
                                    </li>
                                </ul>
                                <div className="excerpt text-secondary">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: post.excerpt,
                                        }}
                                    ></p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="text-center">
                <Link className="btn btn-primary" to="/blog">
                    {t("allNews")}
                </Link>
            </div>
        </div>
    );
};

export default Posts;
