import React from "react";

const About = ({ title, description }) => {
    return (
        <div className="container py-5">
            <h2
                className="section-title section-title-border border-primary font-weight-bold text-center mt-5 mb-3"
                dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
            <div
                className="section-intro mx-auto text-center mb-5"
                dangerouslySetInnerHTML={{ __html: description }}
            ></div>
        </div>
    );
};

export default About;
