import React from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";

const ClientLogos = ({ clientLogos }) => {
    const { t } = useTranslation();

    return (
        <div className="container py-5">
            <h2 className="section-title section-title-border border-primary font-weight-bold text-center mb-5">
                {t("partners")}
            </h2>
            <div className="press-logos row text-center">
                {clientLogos.map((client, index) => (
                    <div
                        key={index}
                        className="align-self-center press-logo px-lg-3 col-6 col-lg-3 mr-0 px-md-5 px-lg-5 mb-3 mb-lg-0"
                    >
                        <a
                            className="logo-link"
                            href={client.logoUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                loading="lazy"
                                className="img-fluid"
                                src={client.publicUrl}
                                alt={client.altText}
                                width={170}
                                height={65}
                            />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ClientLogos;
